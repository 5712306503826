var app = angular.module('msgWeb.controllers.Forecast', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function setupForecastPlot($scope) {

	var series = [];
	series.push({
		name: 'Events',
		type: 'scatter',
		symbolSize: 5,
		symbol: 'circle',
		data: $scope.events
	});

	$scope.forecastTypes.forEach((value) => {
		series.push({
			// we capitalize the first letter only
			name: value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase(),
			type: 'line',
			data: $scope.forecastMap.get(value)
		});
	})

	// Draw the chart
	$scope.chart.setOption({
		legend: {
			show: true,
			top: 5
		},
		dataZoom: [
			{
				type: 'slider',
				bottom: 15,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'inside'
			}
		],
		grid: {
			left: 75,
			top: 30,
			right: 50,
			bottom: 100
		},
		xAxis: {
			name: "Time",
			type: 'time',
			nameLocation: 'middle',
			axisLabel: {
				formatter: {
					month: '{MMM}',
					day: '{d} {MMM}',
					hour: '{d}/{MM} {HH}:{mm}',
				}
			},
			axisLine: { onZero: false },
			nameGap: 30
		},
		yAxis: {
			name: 'Moment magnitude',
			nameLocation: 'middle',
			axisLine: {
				show: true
			},
			nameGap: 50
		},
		series: series,
		useUTC: true,
		animation: false
	});
};

function buildForecastData(events, $scope, forecasts) {
	var value, time, modelData;

	// events
	const eventData = [];
	for (var i = 0; i < events.length; i++) {
		value = events[i];
		if (value.magnitude) {
			eventData.push([value.date.getTime(), value.momentMagnitude]);
		}
	}

	const forecastMap = new Map();
	const forecastTypes = [];
	for (var i = 0; i < forecasts.length; i++) {
		value = forecasts[i];
		time = value.date.getTime();
		for (const [modelName, magnitude] of Object.entries(value.forecasts)) {
			modelData = forecastMap.get(modelName);
			if (modelData == null) {
				modelData = [];
				forecastMap.set(modelName, modelData);
				forecastTypes.push(modelName);
			}
			modelData.push([time, magnitude]);
		}
	}

	forecastTypes.sort();

	$scope.events = eventData;
	$scope.forecastMap = forecastMap;
	$scope.forecastTypes = forecastTypes;
};

app.controller("forecastController", ['$scope', 'DataModel', '$timeout', 'Forecast',
	function($scope, DataModel, $timeout, Forecast) {
		$scope.loading = true;

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			DataModel.getEvents(function(events) {
				Forecast.getForecast(function(forecasts) {
					buildForecastData(events, $scope, forecasts);
					$scope.chart = echarts.init(document.getElementById('chart-forecast'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					setupForecastPlot($scope);

					// events coming from the data model
					var updateFunc = function() {
						setupForecastPlot($scope);
					};
					$scope.$on('eventDataChanged', updateFunc);
					$scope.$on('forecastDataChanged', updateFunc);

					$timeout(function() {
						$scope.loading = false;
					});
				});
			});
		}, 10);
	}]);
