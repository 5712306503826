var app = angular.module('msgWeb.controllers.depthMagnitude', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function createDepthMagnitudeSeriesOptions($scope, Settings) {
	var seriesColors = getDepthMagnitudeSeriesColor($scope, Settings);
	var series = [];
	for (var i = 0; i < 10; i++) {
		series.push({
			id: i.toString(),
			type: 'scatter',
			symbolSize: i == 0 ? 10 : 5,
			symbol: i == 0 ? 'diamond' : 'circle',
			color: seriesColors[i],
			data: $scope.data[i]
		});
	}

	var data = [[], [], []];

	if ($scope.showTrafficLightRegions) {

		switch ($scope.trafficLightMode) {
			case config.magnitudeLabel:
				var criterion = Settings.getSetting('magnitudeCriterion');
				data[0].push([{ yAxis: criterion.amberRedValue }, { yAxis: Number.MAX_VALUE }]);
				data[1].push([{ yAxis: criterion.greenAmberValue }, { yAxis: criterion.amberRedValue }]);
				data[2].push([{ yAxis: -Number.MAX_VALUE }, { yAxis: criterion.greenAmberValue }]);
				break;

			case 'Depth':
				var minDepthCriterion = Settings.getSetting('minDepthCriterion');
				var maxDepthCriterion = Settings.getSetting('maxDepthCriterion');

				var greenMin = maxDepthCriterion ? maxDepthCriterion.greenAmberValue : -Number.MAX_VALUE;
				var greenMax = minDepthCriterion ? minDepthCriterion.greenAmberValue : Number.MAX_VALUE;
				data[2] = [[{ xAxis: greenMin }, { xAxis: greenMax }]];

				if (minDepthCriterion) {
					data[0].push([{ xAxis: minDepthCriterion.amberRedValue }, { xAxis: Number.MAX_VALUE }]);
					data[1].push([{ xAxis: minDepthCriterion.greenAmberValue }, { xAxis: minDepthCriterion.amberRedValue }]);
				}
				if (maxDepthCriterion) {
					data[0].push([{ xAxis: -Number.MAX_VALUE }, { xAxis: maxDepthCriterion.amberRedValue }]);
					data[1].push([{ xAxis: maxDepthCriterion.amberRedValue }, { xAxis: maxDepthCriterion.greenAmberValue }]);
				}
				break;
		}
	}

	series.push({
		id: 'red',
		type: 'scatter',
		markArea: {
			itemStyle: {
				color: Settings.getSetting('redColor')
			},
			data: data[0]
		}
	});
	series.push({
		id: 'amber',
		type: 'scatter',
		markArea: {
			itemStyle: {
				color: Settings.getSetting('amberColor')
			},
			data: data[1]
		}
	});
	series.push({
		id: 'green',
		type: 'scatter',
		markArea: {
			itemStyle: {
				color: Settings.getSetting('greenColor')
			},
			data: data[2]
		}
	});

	return series;
}

function setupDepthMagnitudePlot($scope, Settings) {
	// Draw the chart
	$scope.chart.setOption({
		dataZoom: [
			{ type: 'inside' },
			{
				type: 'slider',
				bottom: 50,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			},
			{
				type: 'slider',
				yAxisIndex: 0,
				right: 20,
				dataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				},
				selectedDataBackground: {
					areaStyle: {
						opacity: 0.7
					}
				}
			}
		],
		toolbox: {
			right: 20,
			top: 0,
			feature: {
				dataZoom: {}
			}
		},
		grid: {
			left: 75,
			top: 30,
			right: 65,
			bottom: 130
		},
		xAxis: {
			name: "Depth (ft)",
			nameLocation: 'middle',
			nameGap: 30,
			axisLine: { onZero: false }
		},
		yAxis: {
			name: config.magnitudeLabel,
			nameLocation: 'middle',
			nameGap: 50,
			max: 2,
			axisLine: { onZero: false }
		},
		series: createDepthMagnitudeSeriesOptions($scope, Settings),
		useUTC: true,
		animation: false
	});
};

function getDepthMagnitudeSeriesColor($scope, Settings) {
	if ($scope.showTrafficLightRegions) {
		return new Array(10).fill('blue');
	}

	var green = Settings.getSetting('greenColor');
	var red = Settings.getSetting('redColor');
	var amber = Settings.getSetting('amberColor');
	var magnitudeCriterion = Settings.getSetting('magnitudeCriterion');
	var minDepthCriterion = Settings.getSetting('minDepthCriterion');
	var maxDepthCriterion = Settings.getSetting('maxDepthCriterion');

	// getting the last item colour
	var lastItemColor = green;
	switch ($scope.trafficLightMode) {
		case config.magnitudeLabel:
			var value = $scope.data[0][0][1];
			if (value > magnitudeCriterion.greenAmberValue) {
				lastItemColor = amber;
				if (value > magnitudeCriterion.amberRedValue) {
					lastItemColor = red;
				}
			}
			break;

		case 'Depth':
			var value = $scope.data[0][0][0];
			if (minDepthCriterion && value.z > minDepthCriterion.greenAmberValue) {
				lastItemColor = amber;
				if (value.z > minDepthCriterion.amberRedValue) {
					lastItemColor = red;
				}
			}
			else if (maxDepthCriterion && value.z < maxDepthCriterion.greenAmberValue) {
				lastItemColor = amber;
				if (value.z < maxDepthCriterion.amberRedValue) {
					lastItemColor = red;
				}
			}
			break;
	}

	switch ($scope.trafficLightMode) {
		case config.magnitudeLabel:
			return [lastItemColor, green, amber, red, green, amber, red, green, amber, red];

		case 'Depth':
			return [lastItemColor, green, green, green, amber, amber, amber, red, red, red];
	}
	return [];
};

function checkDepthMagnitudeTrafficLightSettings($scope, Settings) {
	var magnitudeCriterion = Settings.getSetting('magnitudeCriterion');
	var minDepthCriterion = Settings.getSetting('minDepthCriterion');
	var maxDepthCriterion = Settings.getSetting('maxDepthCriterion');

	$scope.canShowTrafficLights = magnitudeCriterion || minDepthCriterion || maxDepthCriterion;
	$scope.hasSeveralModes = magnitudeCriterion && (minDepthCriterion || maxDepthCriterion);
	$scope.trafficLightModes = [config.magnitudeLabel, 'Depth'];
	if (magnitudeCriterion) {
		$scope.trafficLightMode = $scope.trafficLightModes[0];
	}
	else if (minDepthCriterion || maxDepthCriterion) {
		$scope.trafficLightMode = $scope.trafficLightModes[1];
	}
}

function buildDepthMagnitudeData(events, $scope, Settings) {
	var magnitudeCriterion = Settings.getSetting('magnitudeCriterion');
	var minDepthCriterion = Settings.getSetting('minDepthCriterion');
	var maxDepthCriterion = Settings.getSetting('maxDepthCriterion');

	$scope.data = [[], [], [], [], [], [], [], [], [], []];
	var count = 0;
	if (events.length > 0) {
		var index, value;
		for (var i = 0; i < events.length - 1; i++) {
			value = events[i];
			if (!value.magnitude) {
				continue;
			}
			index = 1;
			if (magnitudeCriterion && value.magnitude > magnitudeCriterion.greenAmberValue) {
				index++;
				if (value.magnitude > magnitudeCriterion.amberRedValue) {
					index++;
				}
			}
			if (minDepthCriterion && value.z > minDepthCriterion.greenAmberValue) {
				if (value.z > minDepthCriterion.amberRedValue) {
					index += 6;
				}
				else {
					index += 3;
				}
			}
			else if (maxDepthCriterion && value.z < maxDepthCriterion.greenAmberValue) {
				if (value.z < maxDepthCriterion.amberRedValue) {
					index += 6;
				}
				else {
					index += 3;
				}
			}
			$scope.data[index].push([value.z / 0.3048 + 690, value.magnitude]);
			count++;
		}

		// last value
		if (count > 0) {
			var lastValue = events[events.length - 1];
			var magnitude = lastValue.magnitude ? lastValue.magnitude : NaN;
			$scope.data[0].push([lastValue.z / 0.3048 + 690, magnitude]);
		}
	}

	if (count == 0) {
		$scope.data[0].push([0, 0]);
	}
};

app.controller("depthMagnitudeController", ['$scope', '$timeout', 'DataModel', 'Settings',
	function($scope, $timeout, DataModel, Settings) {
		$scope.showTrafficLightRegions = false;
		$scope.loading = true;

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			Settings.onSettingsLoaded(function() { // settings need to be loaded first
				checkDepthMagnitudeTrafficLightSettings($scope, Settings);

				DataModel.getEvents(function(events) {
					buildDepthMagnitudeData(events, $scope, Settings);

					$scope.chart = echarts.init(document.getElementById('chart-depth-magnitude'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					setupDepthMagnitudePlot($scope, Settings);

					// events coming from the data model
					$scope.$on('eventDataChanged', function(e, events) {
						setupDepthMagnitudePlot($scope, Settings);
					});

					$timeout(function() {
						$scope.loading = false;
					});
				});
			});
		}, 10);

		$scope.updateTrafficLightColors = function() {
			setupDepthMagnitudePlot($scope, Settings);
		};
	}]);
