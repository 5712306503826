angular.module('forecastService', [])
	.factory('Forecast', ['$rootScope', '$http', function($rootScope, $http) {

		var forecasts;

		function loadForecasts() {

			$http({
				method: 'GET',
				url: '/forecast',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
				.then(function(response) {
					forecasts = response.data;

					forecasts.forEach(function(forecast) {
						forecast.date = new Date(forecast.dateTime);
					});
					forecasts.sort(function(a, b) {
						return a.date.getTime() - b.date.getTime();
					});

					$rootScope.$broadcast('forecastDataChanged');
				},
					function(error) {
						console.error(error);
					});
		};

		if (config.useLogin) {
			$rootScope.$on('loggedIn', function(event, loggedIn) {
				if (loggedIn) {
					loadForecasts();
				}
				else {
					forecasts = null;
				}
			});
			if ($rootScope.loggedIn) {
				loadForecasts();
			}
		}
		else {
			loadForecasts();
		}

		// function which return the expected data synchronously if already here
		// or asynchronously otherwise
		function getForecast(callback) {
			if (forecasts) {
				callback(forecasts.slice()); // we send a copy
			}
			else {
				setTimeout(function() {
					getForecast(callback);
				}, 100);
			}
		};

		return {
			getForecast: getForecast
		};
	}]);
