var app = angular.module('msgWeb.controllers.peakVelocity', [
	'ngRoute',
	'mobile-angular-ui',
	'mobile-angular-ui.core'
]);

function createPeakVelocitySeriesOptions($scope, Settings) {
	var series = [];
	for (var i = 0; i < $scope.stations.length; i++) {
		var station = $scope.stations[i];
		series.push({
			name: station,
			type: 'scatter',
			symbolSize: 5,
			symbol: 'circle',
			color: $scope.showTrafficLightRegions ? 'blue' : null,
			data: $scope.data[i],
			large: true
		});
	}

	if ($scope.showTrafficLightRegions) {
		var criterion = Settings.getSetting('peakVelocityCriterion');

		series.push({
			id: 'red',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('redColor')
				},
				data: [
					[{ yAxis: criterion.amberRedValue }, { yAxis: Number.MAX_VALUE }],
				]
			},
			large: true
		});
		series.push({
			id: 'amber',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('amberColor')
				},
				data: [
					[{ yAxis: criterion.greenAmberValue }, { yAxis: criterion.amberRedValue }],
				]
			},
			large: true
		});
		series.push({
			id: 'green',
			type: 'scatter',
			markArea: {
				itemStyle: {
					color: Settings.getSetting('greenColor')
				},
				data: [
					[{ yAxis: Number.MIN_VALUE }, { yAxis: criterion.greenAmberValue }],
				]
			},
			large: true
		});
	}
	else {
		series.push({ id: 'red', type: 'scatter', markArea: { data: [] } });
		series.push({ id: 'amber', type: 'scatter', markArea: { data: [] } });
		series.push({ id: 'green', type: 'scatter', markArea: { data: [] } });
	}

	return series;
};

function setupPeakVelocityPlot($scope, Settings) {
	// Draw the chart
	$scope.chart.setOption({
		legend: {
			show: true,
			orient: 'vertical',
			type: 'scroll',
			left: 5,
			top: 10,
			borderColor: '#333',
			borderWidth: 0.8,
		},
		dataZoom: [
			{ type: 'inside' },
			{
				type: 'slider',
				bottom: 40,
			},
			{
				type: 'slider',
				yAxisIndex: 0,
			}
		],
		toolbox: {
			right: 20,
			top: 0,
			feature: {
				dataZoom: {}
			}
		},
		tooltip: {
			position: 'top',
			formatter: function(params) {
				return $scope.tooltips[params.seriesIndex][params.dataIndex] + ": " + params.value[0] + ", " + params.value[1].toExponential();
			}
		},
		grid: {
			left: 175,
			top: 30,
			right: 50,
			bottom: 130
		},
		xAxis: {
			name: "Trigger ID",
			nameLocation: 'middle',
			nameGap: 30,
			axisLine: { onZero: false }
		},
		yAxis: {
			name: "Peak Velocity (mm/s)",
			type: "log",
			nameLocation: 'middle',
			nameGap: 50,
			axisLine: { onZero: false },
			axisLabel: {
				formatter: function(value, index) {
					return value.toExponential();
				}
			}
		},
		series: createPeakVelocitySeriesOptions($scope, Settings),
		useUTC: true,
		animation: false
	});
};

function buildPeakVelocityData(triggers, $scope) {
	$scope.data = [];
	$scope.tooltips = [];
	var count = 0;

	if (triggers.length > 0) {
		var stationIndex, trigger;

		$scope.stations.forEach(function() {
			$scope.tooltips.push([]);
			$scope.data.push([]);
		});

		// we get the velocity data
		for (var i = 0; i < triggers.length; i++) {
			var trigger = triggers[i];
			if (trigger.peakVelocity && (!$scope.hideNoiseTriggers || trigger.type !== 'noise')) {
				trigger.peakVelocity.forEach(function(peakVelocityObject) {
					var peakVelocity = peakVelocityObject.peakVelocity;
					if ($scope.componentFilter === "All" || $scope.componentFilter === peakVelocityObject.component) {
						stationIndex = $scope.stations.indexOf(peakVelocityObject.stationName);
						$scope.data[stationIndex].push([trigger.triggerID, peakVelocity]);
						$scope.tooltips[stationIndex].push(peakVelocityObject.stationName + " " + peakVelocityObject.component);
						count++;
					}
				});
			}
		}
	}

	if (count == 0) {
		$scope.data = [[0, 0]];
		$scope.tooltips = [""];
	}
};

function loadStations(triggers, $scope) {
	$scope.stations = [];

	for (var i = 0; i < triggers.length; i++) {
		var trigger = triggers[i];
		if (trigger.peakVelocity) {
			trigger.peakVelocity.forEach(function(peakVelocityObject) {
				if ($scope.stations.indexOf(peakVelocityObject.stationName) < 0) {
					$scope.stations.push(peakVelocityObject.stationName);
				}
			});
		}
	}
	if ($scope.stations.length == 0) {
		$scope.stations.push("");
	}
	else {
		$scope.stations.sort(function(s1, s2) {
			var lim = Math.min(s1.length, s2.length);
			var v1 = Array.from(s1);
			var v2 = Array.from(s2);

			var k = 0;
			while (k < lim) {
				var c1 = v1[k];
				var c2 = v2[k];

				if (c1 !== c2) {
					if ('0' <= c1 && c1 <= '9' && '0' <= c2 && c2 <= '9') {
						var i1 = parseInt(s1.substring(k));
						var i2 = parseInt(s2.substring(k));
						if (!isNaN(i1) && !isNaN(i2)) {
							return i1 - i2;
						}
					}
					return c1.localeCompare(c2);
				}
				k++;
			}
			return s1.length - s2.length;
		});
	}
};

app.controller("peakVelocityController", ['$scope', '$timeout', 'DataModel', 'Settings',
	function($scope, $timeout, DataModel, Settings) {
		$scope.showTrafficLightRegions = false;
		$scope.loading = true;

		$scope.componentFilters = ["All", "E", "N", "Z"];
		$scope.componentFilter = "All";

		// noise filter
		$scope.hideNoiseTriggers = false;

		// we don't start loading the graph straight away to improve the response speed
		$timeout(function() {
			Settings.onSettingsLoaded(function() { // settings need to be loaded first
				$scope.canShowTrafficLights = Settings.hasSetting('peakVelocityCriterion');

				DataModel.getTriggers(function(triggers) {
					loadStations(triggers, $scope);
					buildPeakVelocityData(triggers, $scope);

					$scope.chart = echarts.init(document.getElementById('chart-peak-velocity'));
					window.addEventListener('resize', function() {
						$scope.chart.resize();
					});
					setupPeakVelocityPlot($scope, Settings);

					// events coming from the data model
					$scope.$on('triggerDataChanged', function(e, triggers) {
						loadStations(triggers, $scope);
						buildPeakVelocityData(triggers, $scope);
						setupPeakVelocityPlot($scope, Settings);
					});

					$timeout(function() {
						$scope.loading = false;
					});
				});
			});
		}, 10);

		$scope.updateTrafficLightColors = function() {
			setupPeakVelocityPlot($scope, Settings);
		};

		$scope.updateComponentFilter = function() {
			DataModel.getTriggers(function(triggers) {
				buildPeakVelocityData(triggers, $scope);
				setupPeakVelocityPlot($scope, Settings);
			});
		};
	}]);
