/**
 * service which manages the authentication
 */
angular.module('authenticationService', [])
	.factory('Authentication', ['$timeout', '$http', '$location', '$rootScope',
	                            function($timeout, $http, $location, $rootScope){

		/*
		 * Function called when the button "logout" has been pressed
		 */
		$rootScope.logout = function() {
			$http({
				method: 'GET',
				url: 'logout'
			})
			.then(function (response){
				$rootScope.loggedIn = false;
				$rootScope.$broadcast('loggedIn', false);
				$location.url('/login');
			});
		};
		
		if(config.useLogin) {
			/*
			 * We check if we are not already connected e.g if page has been refreshed
			 */
			$timeout(function() {
				$rootScope.loading = true;
			});
			$http({
				method: 'GET',
				url: 'loggedin'
			})
			.then(function (response){
				if(response.data !== '0') {
					$rootScope.username = response.data.username;
					$rootScope.loggedIn = true;
					$rootScope.$broadcast('loggedIn', true);
					$location.path($rootScope.originalPath);
				}
				$timeout(function() {
					$rootScope.loading = false;
				});
			},
			function (error){
				$timeout(function() {
					$rootScope.loading = false;
				});
			});
		}

	return {
		/**
		 * Tries to log onto the server
		 */
		checkAuthentication: function(loginName, password, callback) {
			$http({
				method: 'POST',
				url: '/login',
				headers: {'Authorization': 'Basic ' + btoa(loginName + ':' + password)}
			})
			.then(function (response){
				$rootScope.username = loginName;
				$rootScope.loggedIn = true;
				$rootScope.$broadcast('loggedIn', true);
				$location.url('/');
			},
			function (response){
				if(callback) {
					if(typeof response.data === 'string' && response.data !== '') {
						callback(response.data);
					}
					else {
						callback('Wrong login or password or connection error.');
					}
				}
			});
		}
	};
}]);
