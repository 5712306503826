angular.module('dataService', [])
	
	.factory('DataModel', ['$http', '$timeout', '$rootScope',
	                       function($http, $timeout, $rootScope){
							   
	// Polyfill for old browsers and IE
	Math.log10 = Math.log10 || function(x) {
		return Math.log(x) / Math.LN10;
	};
		
	var projectData, boreholeData, stationData, triggerData, historicalTriggerData, eventData, historicalEventData;
	var projectUpdateRequired, boreholeUpdateRequired, stationUpdateRequired, triggerUpdateRequired, historicalTriggerUpdateRequired, eventUpdateRequired, historicalEventUpdateRequired;
	var projectDownloading, boreholeDownloading, stationDownloading, triggerDownloading, historicalTriggerDownloading, eventDownloading, historicalEventDownloading;
	
	if(!config.showHistoricalTriggers) {
		historicalTriggerData = [];
		historicalEventData = [];
	}
	
	/*
	 * When all the data has been loaded from the server, we broadcast an event
	 */
	var allDataLoaded = false;
	function checkAllDataLoaded() {
    	if(!allDataLoaded && projectData && boreholeData && stationData && historicalTriggerData && triggerData && eventData && historicalEventData) {
    		allDataLoaded = true;
    		$rootScope.$broadcast('dataLoaded');
    	}
    }
	
	function updateDataModel(updateProject, updateBoreholes, updateStations, updateTriggers, updateHistoricalTriggers, updateEvents, updateHistoricalEvents, notify) {
		
		if(updateProject) {
			projectDownloading = true;
			$http({
				method: 'GET',
				url: '/projectData',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				projectDownloading = false;
				if(response.data.length > 0) {
					projectData = response.data[0];
				}
				else {
					projectData = {};
				}
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('projectDataChanged', projectData);
				}
			},
			function (error){
				projectDownloading = false;
			});
		}
		
		if(updateBoreholes) {
			boreholeDownloading = true;
			$http({
				method: 'GET',
				url: '/boreholeData',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				boreholeDownloading = false;
				response.data.forEach(function(n, i){
					n.toString = function() {
						return this.name;
					}
				});
				boreholeData = response.data;
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('boreholeDataChanged', response.data);
				}
			},
			function (error){
				boreholeDownloading = false;
			});
		}
		
		if(updateStations) {
			stationDownloading = true;
			$http({
				method: 'GET',
				url: '/stationData',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				stationDownloading = false;
				response.data.forEach(function(n, i){
					n.toString = function() {
						return this.stationName;
					}
				});
				stationData = response.data;
				
				stationData.sort(function(st1, st2) {
					var s1 = st1.stationName;
					var s2 = st2.stationName;
					var lim = Math.min(s1.length, s2.length);
					var v1 = Array.from(s1);
					var v2 = Array.from(s2);

					var k = 0;
					while (k < lim) {
						var c1 = v1[k];
						var c2 = v2[k];

						if (c1 !== c2) {

							if ('0' <= c1 && c1 <= '9' && '0' <= c2 && c2 <= '9') {
								var i1 = parseInt(s1.substring(k));
								var i2 = parseInt(s2.substring(k));
								if(!isNaN(i1) && !isNaN(i2)) {
									return i1 - i2;
								}
							}
							return c1.localeCompare(c2);
						}
						k++;
					}
					return s1.length - s2.length;
				});
				
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('stationDataChanged', response.data);
				}
			},
			function (error){
				stationDownloading = false;
			});
		}
		
		if(updateTriggers) {
			triggerDownloading = true;
			$http({
				method: 'GET',
				url: '/triggerData',
				params: {
					projectName: config.projectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				triggerDownloading = false;
				response.data.forEach(function(n, i){
					n.date = new Date(n.startTime);
					n.historical = false;
				});
				triggerData = response.data;
				updateEventTriggerType(response.data, eventData, config.projectName);
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('triggerDataChanged', response.data);
				}
			},
			function (error){
				triggerDownloading = false;
			});
		}
		
		if(updateHistoricalTriggers && config.showHistoricalTriggers) {
			historicalTriggerDownloading = true;
			$http({
				method: 'GET',
				url: '/triggerData',
				params: {
					projectName: config.historicalProjectName,
					dbName: config.dbName
				}
			})
			.then(function (response){
				historicalTriggerDownloading = false;
				response.data.forEach(function(n, i){
					n.date = new Date(n.startTime);
					n.historical = true;
				});
				historicalTriggerData = response.data;
				updateEventTriggerType(response.data, historicalEventData, config.historicalProjectName);
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('historicalTriggerDataChanged', response.data);
				}
			},
			function (error){
				historicalTriggerDownloading = false;
			});
		}
		
		if(updateEvents) {
			eventDownloading = true;
			$http({
				method: 'GET',
				url: '/eventData',
				params: {
					projectName: config.projectName, 
					dbName: config.dbName, 
					user: config.user, 
					analyst: currentAnalyst.name
				}
			})
			.then(function (response){
				eventDownloading = false;
				eventData = getEventData(response, false);
				updateEventTriggerType(triggerData, eventData, config.projectName);
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('eventDataChanged', eventData);
				}
			},
			function (error){
				eventDownloading = false;
			});
		}
		
		if(updateHistoricalEvents && config.showHistoricalTriggers) {
			historicalEventDownloading = true;
			$http({
				method: 'GET',
				url: '/eventData',
				params: {
					projectName: config.historicalProjectName, 
					dbName: config.dbName, 
					user: config.user, 
					analyst: currentAnalyst.name
				}
			})
			.then(function (response){
				historicalEventDownloading = false;
				historicalEventData = getEventData(response, true);
				updateEventTriggerType(historicalTriggerData, historicalEventData, config.historicalProjectName);
				checkAllDataLoaded();
				
				if(notify) {
					$rootScope.$broadcast('historicalEventDataChanged', historicalEventData);
				}
			},
			function (error){
				historicalEventDownloading = false;
			});
		}
	};
	
	function getEventData(response, historical) {
		var events = response.data;
		if(events.length > 0) {
			events.forEach(function(event, i){
				// adding dates
				event.date = new Date(event.originTime);
				event.historical = historical;
				
				// toString function
				event.toString = function() {
					var res = formatDate(event.date);
					var magnitude = config.getMagnitude(event);
					if(magnitude) {
						res += '\u2003' + magnitude.toFixed(2);
					}
					return res;
				}
			});
			
			// sorting by date
			events.sort(function(a, b) {
				return a.date.getTime() - b.date.getTime();
			});
			
			// seismic moments
			var cumulSeismicMoment = 0;
			events.forEach(function(event, i){
				
				if(typeof event.seismicMoment === 'number') {
					// moment magnitude
					event.momentMagnitude = 2 * Math.log10(event.seismicMoment) / 3 - 6.07;
					
					// cumulative seismic moment
					cumulSeismicMoment += event.seismicMoment;
					event.cumulSeismicMoment = cumulSeismicMoment;
				}
				else {
					event.seismicMoment = undefined;
				}
				
				// magnitude
				event.magnitude = config.getMagnitude(event);
			});
		}
		return events;
	};
	
	function updateEventTriggerType(triggerData, eventData, projectName) {
		if(eventData) {
			eventData.forEach(function(event){
				var trigger = getTrigger(triggerData, event.triggerID);
				event.triggerType = trigger ? trigger.type : "undef";
				if(trigger) {
					event.imageName = trigger.imageName;
					event.project = projectName;
				}
			});
		}
	};
	
	function getTrigger(triggerData, triggerID) {
		if(triggerData) {
			for(var i = 0; i < triggerData.length; i++) {
				if(triggerData[i].triggerID == triggerID) {
					return triggerData[i];
				}
			}
		}
    	return null;
	};
	
	$rootScope.$on('analystChanged', function() {
		eventData = null;
		updateDataModel(false, false, false, false, false, true, true, false);
	});
	
	if(config.useLogin) {
		$rootScope.$on('loggedIn', function(event, loggedIn) {
			if(loggedIn) {
				//initial load
				updateDataModel(true, true, true, true, true, true, true, false);
			}
			else {
				projectData = boreholeData = stationData = triggerData = eventData = historicalTriggerData = historicalEventData = null;
				allDataLoaded = false;
			}
		});
		if($rootScope.loggedIn) {
			//initial load
			updateDataModel(true, true, true, true, true, true, true, false);
		}
	}
	else {
		//initial load
		updateDataModel(true, true, true, true, true, true, true, false);
	}
	
	// timer to update the data model
	var interval = 5000; // ms
	function checkUpdateDataModel() {top
		if((!config.useLogin || $rootScope.loggedIn) && !projectDownloading && !boreholeDownloading && !stationDownloading && !triggerDownloading && !historicalTriggerDownloading && !eventDownloading && !historicalEventDownloading) {
			updateDataModel(projectUpdateRequired, boreholeUpdateRequired, stationUpdateRequired, triggerUpdateRequired, historicalTriggerUpdateRequired, eventUpdateRequired, historicalEventUpdateRequired, true);
			projectUpdateRequired = boreholeUpdateRequired = stationUpdateRequired = triggerUpdateRequired = eventUpdateRequired = historicalEventUpdateRequired = historicalTriggerUpdateRequired = false;
		}
		setTimeout(checkUpdateDataModel, interval);
	}
	setTimeout(checkUpdateDataModel, interval);
	
	// getting events from the server when data has changed
	$rootScope.$on("dataChange", function (event, args) {
		if(args.dbName === config.dbName) {
			if(args.type === 'project') { // every project data are in the same mongoDB collection
				projectUpdateRequired = true;
			}
			else if(args.projectName === config.projectName) {
				if(args.type === 'borehole') {
					boreholeUpdateRequired = true;
				}
				else if(args.type === 'station') {
					stationUpdateRequired = true;
				}
				else if(args.type === 'trigger') {
					triggerUpdateRequired = true;
				}
				else if(args.type === 'event') {
					eventUpdateRequired = true;
				}
			}
			else if(args.projectName === config.historicalProjectName) {
				if(args.type === 'trigger') {
					historicalEventUpdateRequired = true;
				}
				else if(args.type === 'event') {
					historicalEventUpdateRequired = true;
				}
			}
		}
	});

	// object which access the data synchronously
    var directDataAccess = {
    	getProject: function() {
    		return projectData;
		},

		getBoreholes: function() {
			return boreholeData;
		},

		getStations: function() {
			return stationData;
		},

		getTriggers: function() {
			return triggerData;
		},
		
		getHistoricalTriggers: function() {
			return historicalTriggerData;
		},

		getEvents: function() {
			return eventData;
		},
		
		getHistoricalEvents: function() {
			return historicalEventData;
		}
    };
    
    // function which return the expected data synchronously if already here
    // or asynchronously otherwise
    function getData(accessFunction, callback) {
    	var data = accessFunction();
    	if(data) {
    		callback(data.slice()); // we send a copy
    	}
    	else {
    		setTimeout(function() {
    			getData(accessFunction, callback);
			}, 100);
    	}
    };
	
	function doGetProject(callback) {
    	var project = directDataAccess.getProject();
		if(project) {
			callback(project);
		}
		else {
			setTimeout(function() {
				doGetProject(callback);
			}, 100);
		}
    };
    
    function findData(dataSet, triggerID, project) {
    	for(var i = 0; i < dataSet.length; i++) {
    		if(dataSet[i].triggerID == triggerID && dataSet[i].project == project) {
    			return dataSet[i];
    		}
    	}
    	return null;
    };
	
	return {
		// functions which return the data data synchronously if already here
	    // or asynchronously otherwise
		getProject: doGetProject,

		getBoreholes: function(callback) {
			getData(directDataAccess.getBoreholes, callback);
		},

		getStations: function(callback) {
			getData(directDataAccess.getStations, callback);
		},

		getTriggers: function(callback, historical) {
			if(historical) {
				getData(directDataAccess.getHistoricalTriggers, callback);
			}
			else {
				getData(directDataAccess.getTriggers, callback);
			}
		},
		
		getAllTriggers: function(callback) {
			var thisObj = this;
			if(historicalTriggerData && triggerData) {
				this.getTriggers(function(triggers) {
					thisObj.getTriggers(function(historicalTriggers) {
						callback(triggers.slice().concat(historicalTriggers));
					}, true);
				}, false);
			}
			else {
				setTimeout(function() {
					thisObj.getAllTriggers(callback);
				}, 100);
			}
		},

		getEvents: function(callback, historical) {
			if(historical) {
				getData(directDataAccess.getHistoricalEvents, callback);
			}
			else {
				getData(directDataAccess.getEvents, callback);
			}
		},
		
		getAllEvents: function(callback) {
			var thisObj = this;
			if(eventData && historicalEventData && triggerData && historicalTriggerData) {
				this.getEvents(function(events) {
					thisObj.getEvents(function(historicalEvents) {
						callback(events.slice().concat(historicalEvents));
					}, true);
				}, false);
			}
			else {
				setTimeout(function() {
					thisObj.getAllEvents(callback);
				}, 100);
			}
		},
		
		getCatalogueData: function(callback, historicalToo) { // combination of triggers and events
			var thisObj = this;
			
			if(historicalTriggerData && triggerData && eventData && historicalEventData) {
				var dataSet = [];
				
				var eventCallback = function(events) {
					events.forEach(function(event, i) {
						var project = event.historical ? config.historicalProjectName : config.projectName;
						var triggerData = findData(dataSet, event.triggerID, project);
						if(triggerData) {
							triggerData.x = event.x;
							triggerData.y = event.y;
							triggerData.z = event.z;
							triggerData.magnitude = event.magnitude;
						}
					});
					callback(dataSet);
				};
				
				var triggerCallback = function(triggers) {
					triggers.forEach(function(trigger, i) {
						dataSet[i] = {
								triggerID: trigger.triggerID,
								date: trigger.date,
								triggerTime: formatDate(trigger.date),
								triggerType: trigger.type,
								project: trigger.historical ? config.historicalProjectName : config.projectName,
								imageName: trigger.imageName,
								peakVelocity: trigger.peakVelocity ? trigger.peakVelocity : []
						};
					});

					if(historicalToo) {
						thisObj.getAllEvents(eventCallback);
					}
					else {
						thisObj.getEvents(eventCallback);
					}
				};
				
				if(historicalToo) {
					thisObj.getAllTriggers(triggerCallback);
				}
				else {
					thisObj.getTriggers(triggerCallback);
				}
			}
			else {
				setTimeout(function() {
					thisObj.getCatalogueData(callback, historicalToo);
				}, 100);
			}
		},
		
		calcMaxVelocity: function(dataSet, Settings) {
			
			// we check whether maxVelocity is already calculated first
			if(dataSet.length > 0 && !dataSet[0].maxVelocity) {
				// list of stations used to calculate the max velocity
				var pvStations = Settings.getSetting('velMaxStations');
				if(pvStations.length < 1
						|| pvStations == undefined
						|| !Array.isArray(pvStations)) {
					pvStations = null;
				}
				
				dataSet.forEach(function(n){
					if(!n.peakVelocity) {
						n.peakVelocity = [];
					}
					
					if(pvStations != null) {
						n.peakVelocity = n.peakVelocity.filter(function (velocity) {
						    return pvStations.includes(velocity.stationName);
						});
					}
					
					n.maxVelocity = 0;
					n.peakVelocity.forEach(function(peakVelocity) {
						if(n.maxVelocity < peakVelocity.peakVelocity) {
							n.maxVelocity = peakVelocity.peakVelocity;
						}
					});
				});
			}
		}
	};
}]);
