var app = angular.module('msgWeb.controllers.Main', [
                                                     'ngRoute',
                                                     'mobile-angular-ui',
                                                     'mobile-angular-ui.core'
                                                     ]);

/**
 * Allows to display values as HTML (i.e M<sub><small>L</small></sub>)
 */
app.filter('escapeHtml', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});

var MapMenu = {
		name: 'Map',
    	ref: '#!/map',
    	menuLink: 'mapMenu',
    	chevron: 'right'
};
var PGVMapMenu = {
		name: 'Peak ground velocity map',
    	ref: '#!/peakGroundVelocityMap',
    	menuLink: 'peakGroundVelocityMapMenu',
    	chevron: 'right'
};
var mainMenu = [
				{
					name: 'Settings',
					ref: '#!/settings'
				},
                {
                	name: 'Catalogue',
                	ref: '#!/catalogue'
                },
                MapMenu,
                {
                	name: '3D View',
                	ref: '#!/3dView',
                	menuLink: 0
                },
                {
                	name: 'Event History',
                	ref: '#!/eventHistory',
                	menuLink: 0
                },
                {
                	name: 'Event Depth',
                	ref: '#!/eventDepth',
                	menuLink: 0
                },
                {
                	name: 'Trigger History',
                	ref: '#!/triggerHistory',
                	menuLink: 0
                },
                {
                	name: 'Trigger Type History',
                	ref: '#!/triggerTypeHistory',
                	menuLink: 0
                },
                {
                	name: 'Trigger Type',
                	ref: '#!/triggerType',
                	menuLink: 0
                },
                {
                	name: 'Time x Magnitude',
                	ref: '#!/timeMagnitude',
                	menuLink: 0
                },
                {
                	name: 'Depth x Magnitude',
                	ref: '#!/depthMagnitude',
                	menuLink: 0
                },
                {
                	name: 'Peak Velocity',
                	ref: '#!/peakVelocity',
                	menuLink: 0
                },
                {
                	name: 'V<sub><small>MAX</small></sub> Time History',
                	ref: '#!/vMaxTimeHistory',
                	menuLink: 0
                },
                {
                	name: 'V<sub><small>MAX</small></sub> Trigger History',
                	ref: '#!/vMaxTriggerHistory',
                	menuLink: 0
                },
                {
                	name: 'V<sub><small>MAX</small></sub> x Magnitude',
                	ref: '#!/vMaxMagnitude',
                	menuLink: 0
                }/*,
                PGVMapMenu*/];
                
var eventForecastMenu = {
	name: 'Event Forecasting',
	ref: '#!/forecast',
	menuLink: 0
};
                
// removes the hidden menus
mainMenu = mainMenu.filter(function(menu) {
	return (menu.hidden === true) ? false : true;
});

app.controller('menuController', ['$scope', '$rootScope', '$timeout', 'DataModel', '$window', '$route',
                                  function($scope, $rootScope, $timeout, DataModel, $window, $route) {
	// init
	$scope.menu = 'noMenu';
	$scope.menus = [];
	$scope.stations = [];
	$scope.events = [];
	
	$scope.map = {
			showEvents: false,
			showEventToggle: false,
			showStations: false,
			showStationToggle: false,
			onlyEventsWithPGVData: false,

			swapEventToggle: function(menu) {
				if($scope.map.showEvents) {
					$scope.map.showEvents = false;
				}
				else {
					$scope.map.showEvents = true;
					$scope.map.showStations = false;
				}
				$scope.updateMenu($scope.menu);
			},

			swapStationToggle: function(menu) {
				if($scope.map.showStations) {
					$scope.map.showStations = false;
				}
				else {
					$scope.map.showStations = true;
					$scope.map.showEvents = false;
				}
				$scope.updateMenu($scope.menu);
			},

			selectObject: function(menu) {
				if($scope.selectedMenu) {
					$scope.selectedMenu.selected = false;
					$scope.selectedMenu = null;
				}
				if($scope.peakGroundVelocityMapMenu) {
					menu.selected = true;
					$scope.selectedMenu = menu;
				}
				
				// event sent to the map controllers
				$rootScope.$broadcast('selectObject', {name: menu.name, type: menu.type});
			}
	};

	// menu actions
	$scope.runAction = function(menu) {
		if(menu.action) {
			menu.action(menu);
		}
		else if(menu.menuLink) {
			$scope.map.showEvents = false;
			$scope.map.showStations = false;
			$scope.updateMenu(menu.menuLink);
		}
	};
	$scope.openSnapshot = function(imagePath) {
		$timeout(function() {
			$window.open(imagePath);
		}, 10);
	};
	
	var buildMapMenu = function() {
		var index = 0;
		$scope.menus = [];

		// back
		$scope.menus[index++] = {
				name: 'Main menu',
				menuLink: 'mainMenu',
				chevron: 'left'
		};

		// stations
		if($scope.map.showStationToggle) {
			$scope.menus[index++] = {
					name: 'Stations',
					chevron: $scope.map.showStations ? 'minus' : 'plus',
					action: $scope.map.swapStationToggle
			};
		}
		if($scope.map.showStations) {
			for(var i = 0; i < $scope.stations.length; i++) {
				$scope.menus[index++] = {
						name: $scope.stations[i].stationName,
						type: 'station',
						size: 12,
						action: $scope.map.selectObject
				};
			}
		}

		// events
		if($scope.map.showEventToggle) {
			$scope.menus[index++] = {
					name: 'Events (origin time, ' + config.magnitudeSymbol + ')',
					chevron: $scope.map.showEvents ? 'minus' : 'plus',
					action: $scope.map.swapEventToggle
			};
		}
		if($scope.map.showEvents) {
			// we don't loads all the events straight away, we load them bit by bit to improve the response time
			var nObjects = 25;
			var startIndex = $scope.events.length;
			var menus = $scope.menus;
			
			var sortedEvents = $scope.events.slice();
			sortedEvents.sort(function(e1, e2) {
				return e1.date.getTime() - e2.date.getTime();
			});
			
			var addMore = function() {
				if(menus != $scope.menus) { // if the menu has changed while loading, we stop
					return;
				}
				var min = Math.max(startIndex - nObjects, 0), max = startIndex - 1;
				$timeout(function() {
					for (var i = max; i >= min; i--) {
						var event = sortedEvents[i];
						
						if(event.triggerType !== 'noise'
								&& (!$scope.map.onlyEventsWithPGVData
								|| ((event.pgvCirclesData && event.pgvCirclesData.length > 0))
									&& (event.rHypVelocities && event.rHypVelocities.length > 0))) {
							var menu = {
								name: event.toString(),
								type: 'event',
								size: 12,
								action: $scope.map.selectObject,
								imagePath: '/snapshot/' + event.project + '/' + event.imageName
							};
							menus.push(menu);
							if(menus.length == 2 && $scope.peakGroundVelocityMapMenu) {
								$scope.runAction(menu);
							}
						}
					}
				});
				startIndex -= nObjects;
				nObjects += 50; // we increase the number every time to go faster
				if(startIndex >= 0) {
					setTimeout(addMore, 1);
				}
			};
			addMore();
		}
	};

	// update menu
	$scope.updateMenu = function(menu) {
		$scope.menu = menu;
		$scope.peakGroundVelocityMapMenu = false;

		switch(menu) {
		case 'noMenu':
			$scope.menus = [];
			break;
			
		case 'mainMenu':
			$scope.menus = mainMenu;
			break;

		case 'mapMenu':
			$scope.map.showEventToggle = true;
			$scope.map.showStationToggle = true;
			$scope.map.onlyEventsWithPGVData = false;
			buildMapMenu();
			break;
			
		case 'peakGroundVelocityMapMenu':
			$scope.peakGroundVelocityMapMenu = true;
			$scope.map.showEventToggle = false;
			$scope.map.showEvents = true;
			$scope.map.showStationToggle = false;
			$scope.map.onlyEventsWithPGVData = true;
			buildMapMenu();
			break;
		}
	};
	
	function loadData() {
		$scope.updateMenu('mainMenu');
		
		const index = mainMenu.indexOf(eventForecastMenu);
		if (config.showForecast && $rootScope.username === "msg-user") {
			if(index == -1) {
				mainMenu.push(eventForecastMenu);
			}
		}
		else if(index != -1) {
			mainMenu.splice(index, 1);
		}
		
		// load the data
		DataModel.getStations(function(data){
			$scope.stations = data;
			$scope.updateMenu($scope.menu);
		});
		DataModel.getAllEvents(function(data){
			$scope.events = data;
			$scope.updateMenu($scope.menu);
		});
	}
	
	if(config.useLogin) {
		$scope.$on('loggedIn', function(event, loggedIn) {
			if(loggedIn) {
				loadData();
			}
			else {
				$scope.updateMenu('noMenu');
			}
		});
		if($rootScope.loggedIn) {
			loadData();
		}
	}
	else {
		loadData();
	}
	
	$scope.onURLChanged = function(templateUrl) {
		if(templateUrl === 'map.html') {
			$scope.runAction(MapMenu);
		}
		else if(templateUrl === 'peakGroundVelocityMap.html') {
			$scope.runAction(PGVMapMenu);
		}
	};
	
	$scope.onURLChanged($route.current.templateUrl);
	$scope.$on('$routeChangeSuccess', function($event, next, current) {
		if(current) {
			if(current.loadedTemplateUrl === 'map.html'
				&& next.loadedTemplateUrl !== 'map.html') {
				$scope.updateMenu('mainMenu');
			}
			else if(current.loadedTemplateUrl === 'peakGroundVelocityMap.html'
				&& next.loadedTemplateUrl !== 'peakGroundVelocityMap.html') {
				$scope.updateMenu('mainMenu');
			}
		}
		$scope.onURLChanged(next.loadedTemplateUrl);
	});
	
	$rootScope.$on('analystChanged', function() {
		loadData();
	});
}]);
